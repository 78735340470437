<template>
  <div>
    <div class="table-responsive">
      <table
        class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
      >
        <thead>
          <tr class="text-left">
            <th style="min-width: 100px">No</th>
            <th style="min-width: 120px">
              {{
                statistical_status === display_view.marker ? "Marker" : "Class"
              }}
            </th>
            <th
              style="min-width: 120px"
              v-if="statistical_status === display_view.marker"
            >
              Weight
            </th>
            <th style="min-width: 120px">Tổng số ticket</th>
            <th style="min-width: 120px">Ticket chưa chấm</th>
            <th style="min-width: 120px">Ticket đã chấm</th>
            <th style="min-width: 120px">KPI những ticket đã chấm</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, i) in statistical_filter">
            <tr v-bind:key="i">
              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >#{{ i + 1 }}</span
                >
              </td>
              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >{{ item.name }}</span
                >
              </td>
              <td v-if="statistical_status === display_view.marker">
                <template v-if="item.config">
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >{{ item.config.weight }}</span
                  >
                </template>
              </td>
              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >{{
                    item.tickets.unmarked.length + item.tickets.marked.length
                  }}</span
                >
              </td>
              <td>
                <div class="d-flex">
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    :class="{
                      'text-decoration-underline':
                        item.tickets.unmarked.length > 0,
                      'light-blue--text': item.tickets.unmarked.length > 0,
                    }"
                    @click="openDialogListTicket(item.tickets.unmarked)"
                  >
                    {{ item.tickets.unmarked.length }}
                  </span>
                  <span v-if="statistical_status === 1" class="ml-1">
                    ( Point: {{ item.point_unmarked }} )
                  </span>
                </div>
              </td>
              <td>
                <div class="d-flex">
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    :class="{
                      'text-decoration-underline':
                        item.tickets.marked.length > 0,
                      'light-blue--text': item.tickets.marked.length > 0,
                    }"
                    @click="openDialogListTicket(item.tickets.marked)"
                  >
                    {{ item.tickets.marked.length }}
                  </span>
                  <div style="display: inline-grid">
                    <span v-if="statistical_status === 1" class="ml-1">
                      ( Point: {{ item.point_marked }} )
                    </span>
                    <span v-if="statistical_status === 1" class="ml-1">
                      ( Point Punctual: {{ item.point_marked_punctual }} )
                    </span>
                    <span v-if="statistical_status === 1" class="ml-1">
                      ( Point Late: {{ item.point_marked_late }} )
                    </span>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-dark-75 d-block font-size-lg mb-1">
                  <span class="font-weight-bolder">Tổng</span>:
                  {{ statisticalKpiTicketMarkedTotal(item) }}%
                </p>
                <p class="text-dark-75 d-block font-size-lg mb-1">
                  <span class="font-weight-bolder">IPP</span>:
                  {{ statisticalKpiTicketMarkedIPP(item) }}%
                </p>
                <p class="text-dark-75 d-block font-size-lg mb-1">
                  <span class="font-weight-bolder">Prep</span>:
                  {{ statisticalKpiTicketMarkedPrep(item) }}%
                </p>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <div>
      <v-row justify="center">
        <v-dialog
          v-model="dialogListTicket"
          max-width="10000px"
          scrollable
          persistent
        >
          <v-card>
            <v-card-title>
              <v-col cols="11">
                <span class="headline">Danh sách ticket</span>
              </v-col>
              <v-col cols="1">
                <v-icon @click="dialogListTicket = false" right
                  >mdi-close</v-icon
                >
              </v-col>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="6" class="d-flex">
                    <label class="text-body-1 font-weight-medium mb-6"
                      >Hiển thị tickets:</label
                    >
                    <v-radio-group
                      class="mt-0 pt-0"
                      v-model="view_display_student"
                      row
                    >
                      <v-radio
                        label="Cả hai"
                        :value="display_student.all"
                      ></v-radio>
                      <v-radio
                        label="học sinh ipp"
                        :value="display_student.ipp"
                      ></v-radio>
                      <v-radio
                        label="học sinh prep"
                        :value="display_student.prep"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="6" class="text-right">
                    <export-excel
                      class="btn btn-primary"
                      :data="transfomrDataTicketToExport(list_ticket_filter)"
                      :fields="json_fields_excel"
                      worksheet="Thống kê"
                      name="thong_ke.xls"
                    >
                      Download Excel
                      <v-icon class="mb-1" small left
                        >mdi-export-variant</v-icon
                      >
                    </export-excel>
                  </v-col>
                  <v-col cols="12" class="mb-0 pb-0">
                    <label class="text-h6 mb-0"
                      ><b>Tổng</b>: {{ list_ticket_filter.length }}</label
                    >
                    <label class="text-h6 mb-0 ml-4"
                      ><b>Point</b>:
                      {{
                        calculatorPointFromListTicket(list_ticket_filter)
                      }}</label
                    >
                    <label class="text-h6 mb-0 ml-4"
                      ><b>Tổng ticket chấm đúng hạn</b>:
                      {{
                        dataTicketPunctualMarking(list_ticket_filter).length
                      }}</label
                    >
                    <label class="text-h6 mb-0 ml-4"
                      ><b>Kpi chấm bài</b>:
                      {{
                        calculatorKpiPunctualMarking(list_ticket_filter)
                      }}%</label
                    >
                  </v-col>
                  <v-col cols="12">
                    <div class="table-responsive">
                      <table
                        class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
                      >
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Khóa học</th>
                            <th scope="col">Học sinh</th>
                            <th scope="col">Lớp</th>
                            <th scope="col" v-if="statistical_status !== 1">
                              Marker
                            </th>
                            <th scope="col">Time</th>
                            <th scope="col">Point</th>
                            <th scope="col">Trạng thái chấm bài</th>
                            <th scope="col">Lịch sử ticket</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="(ticket, i) in list_ticket_filter">
                            <tr :key="i">
                              <td scope="row">
                                <span
                                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                                  >#{{ ticket.id }}</span
                                >
                              </td>
                              <td>
                                <p
                                  class="text-dark-75 font-weight-medium d-block font-size-lg"
                                >
                                  <span class="font-weight-bolder"
                                    >Course:</span
                                  >
                                  {{ displayFieldCourse(ticket.course) }}
                                </p>
                                <p
                                  class="text-dark-75 font-weight-medium d-block font-size-lg"
                                >
                                  <span class="font-weight-bolder">Test:</span>
                                  {{ ticket.test.title }}
                                </p>
                              </td>
                              <td>
                                <p
                                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                                >
                                  {{ ticket.student.name }}
                                </p>
                              </td>
                              <td>
                                <p
                                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                                  v-if="ticket.prep_class != null"
                                >
                                  {{ ticket.prep_class.name }}
                                </p>
                              </td>
                              <td v-if="statistical_status !== 1">
                                <p
                                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                                  v-if="ticket.marker"
                                >
                                  {{ ticket.marker.name }}
                                </p>
                              </td>
                              <td>
                                <p
                                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                                  v-if="ticket.created_at"
                                >
                                  Ngày nộp:
                                  {{ formatDispayTime(ticket.created_at) }}
                                </p>
                                <p
                                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                                  v-if="ticket.marking_date"
                                >
                                  Ngày chấm: {{ ticket.marking_date }}
                                </p>
                              </td>
                              <td>
                                <p
                                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                                  v-if="ticket.point"
                                >
                                  {{ ticket.point }}
                                </p>
                              </td>
                              <td>
                                <p
                                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                                  v-if="ticket.marking_deadline"
                                >
                                  Hạn chấm: {{ ticket.marking_deadline }}
                                </p>
                                <template
                                  v-if="
                                    ticket.marking_status &&
                                    ticket.marking_status === 2
                                  "
                                >
                                  <span
                                    class="label label-lg label-inline font-weight-bold py-4 label-light-danger"
                                    >Chấm muộn</span
                                  >
                                </template>
                              </td>
                              <td>
                                <div v-if="ticket.marker_history">
                                  <span
                                    class="text-dark-75 d-block font-size-lg"
                                  >
                                    <b>Giáo viên cũ</b>:
                                    {{
                                      getLatsObjMarkerByData(
                                        ticket.marker_history
                                      ).old_marker
                                    }}
                                  </span>
                                  <span
                                    class="text-dark-75 d-block font-size-lg"
                                  >
                                    <b>Người gắn ticket</b>:
                                    {{
                                      getLatsObjMarkerByData(
                                        ticket.marker_history
                                      ).modified_user
                                    }}
                                  </span>
                                  <span
                                    class="text-dark-75 d-block font-size-lg"
                                  >
                                    <b>Thời gian</b>:
                                    {{
                                      getLatsObjMarkerByData(
                                        ticket.marker_history
                                      ).created_at
                                    }}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div style="display: flex">
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                      <router-link
                                        :to="{
                                          name: 'MarkingTicketAnswer',
                                          params: { id: ticket.id },
                                        }"
                                        target="_blank"
                                      >
                                        <button
                                          class="btn btn-icon btn-light-primary btn-sm"
                                          v-bind="attrs"
                                          v-on="on"
                                          v-if="
                                            canAccess('marking-ticket-update')
                                          "
                                        >
                                          <span
                                            class="svg-icon svg-icon-md svg-icon-primary"
                                          >
                                            <v-icon color=""
                                              >mdi-eye-settings</v-icon
                                            >
                                          </span>
                                        </button>
                                      </router-link>
                                    </template>
                                    <span>Marking</span>
                                  </v-tooltip>
                                </div>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <button
                class="btn text-uppercase mr-2"
                style="color: #f64e60"
                @click="dialogListTicket = false"
              >
                Close
              </button>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </div>
</template>

<script>
const DISPLAY_BY_MARKER = 1;
const DISPLAY_BY_CLASS = 2;

export default {
  name: "DisplayMarker",
  props: {
    data_statistical: {
      type: Array,
      default: () => [],
    },
    markers: {
      type: Array,
      default: () => [],
    },
    statistical_status: {
      type: Number,
      default: 1,
    },
    filter_input: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      display_student: {
        all: 1,
        ipp: 2,
        prep: 3,
      },
      list_ticket: [],
      list_ticket_filter: [],
      dialogListTicket: false,
      view_display_student: 1,
      display_view: {
        marker: DISPLAY_BY_MARKER,
        class: DISPLAY_BY_CLASS,
      },
      json_fields_excel: {
        Stt: "id",
        "Tên khóa học": "course",
        "Tên bài học": "test",
        "Học sinh": "student",
        Lớp: "class",
        "Ngày nộp": "created_at",
        "Ngày chấm": "marking_date",
        Point: "point",
        "Hạn chấm": "marking_deadline",
        "Trạng thái chấm": "marking_status",
      },
    };
  },
  watch: {
    view_display_student(val) {
      this.resetDataTicketByFilter(val);
    },
    dialogListTicket(val) {
      if (val) {
        this.view_display_student = 1;
      }
    },
  },
  computed: {
    statistical_filter() {
      if (this.filter_input) {
        return this.data_statistical.filter((item) => {
          return this.filter_input
            .toLowerCase()
            .split(" ")
            .every((v) => item.name.toLowerCase().includes(v));
        });
      } else {
        return this.data_statistical;
      }
    },
  },
  methods: {
    canAccess(permission) {
      return this.$utils.canAccess(permission);
    },
    openDialogListTicket(tickets) {
      this.list_ticket = JSON.parse(JSON.stringify(tickets));
      this.list_ticket_filter = JSON.parse(JSON.stringify(tickets));
      this.dialogListTicket = true;
    },
    displayFieldCourse(json) {
      if (this.$utils.IsJsonString(json.name)) {
        let course_name = JSON.parse(json.name);
        return course_name.vi;
      }
      return "";
    },
    resetDataTicketByFilter(value) {
      let data = JSON.parse(JSON.stringify(this.list_ticket));
      if (value === this.display_student.all) {
        this.list_ticket_filter = data;
      } else if (value === this.display_student.ipp) {
        this.list_ticket_filter = data.filter((e) => {
          return e.prep_class != null;
        });
      } else {
        this.list_ticket_filter = data.filter((e) => {
          return e.prep_class == null;
        });
      }
    },
    calculatorPointFromListTicket(tickets) {
      if (tickets.length <= 0) {
        return 0;
      }
      let filter_ticket = tickets.filter((ticket) => {
        return ticket.point != null;
      });
      let totalPoint = 0;
      for (let i = 0; i < filter_ticket.length; i++) {
        totalPoint += filter_ticket[i].point;
      }
      return totalPoint;
    },
    getLatsObjMarkerByData(markerHistory) {
      let data = {
        old_marker: null,
        modified_user: null,
        created_at: null,
      };
      if (markerHistory.length <= 0) {
        return data;
      }
      let obj_marker = markerHistory[markerHistory.length - 1];
      let filter_marker_old = this.markers.filter((marker) => {
        return marker.id === obj_marker.old_marker_id;
      })[0];
      data.old_marker =
        filter_marker_old === undefined ? null : filter_marker_old.name;

      let filter_modified_user = this.markers.filter((marker) => {
        return marker.id === obj_marker.modified_user_id;
      })[0];
      data.modified_user =
        filter_modified_user === undefined ? null : filter_modified_user.name;

      let date_string = obj_marker.created_at;
      data.created_at =
        date_string.substring(10, 0) + " " + date_string.substring(19, 11);
      return data;
    },
    formatDispayTime(time_string) {
      return time_string.substring(0, 10) + " " + time_string.substring(11, 19);
    },
    dataTicketPunctualMarking(tickets) {
      if (tickets.length <= 0) {
        return [];
      }
      let filter_ticket = tickets.filter((ticket) => {
        return ticket.marking_status !== 2;
      });
      return filter_ticket;
    },
    calculatorKpiPunctualMarking(tickets) {
      let totalTicket = tickets.length;
      let totalPunctualTicket = this.dataTicketPunctualMarking(tickets).length;
      let ratio = totalPunctualTicket / totalTicket;
      if (isNaN(ratio)) {
        return 0;
      }
      let kpi = ratio * 100;
      return kpi.toFixed(2);
    },
    statisticalKpiTicketMarkedTotal(data_statistical) {
      let dataTicket = data_statistical.tickets.marked;
      return this.calculatorKpiPunctualMarking(dataTicket);
    },
    statisticalKpiTicketMarkedIPP(data_statistical) {
      let dataTicket = data_statistical.tickets.marked.filter(
        (e) => e.class_id !== null
      );
      return this.calculatorKpiPunctualMarking(dataTicket);
    },
    statisticalKpiTicketMarkedPrep(data_statistical) {
      let dataTicket = data_statistical.tickets.marked.filter(
        (e) => e.class_id === null
      );
      return this.calculatorKpiPunctualMarking(dataTicket);
    },
    transfomrDataTicketToExport(tickets) {
      let vm = this;
      return tickets.map(function (ticket) {
        return {
          id: ticket.id,
          course: vm.displayFieldCourse(ticket.course),
          test: ticket.test.title,
          student: ticket.student.name,
          class: ticket.prep_class === null ? "" : ticket.prep_class.name,
          created_at: ticket.created_at
            ? vm.formatDispayTime(ticket.created_at)
            : "",
          marking_date: ticket.marking_date ? ticket.marking_date : "",
          point: ticket.point ? ticket.point : "",
          marking_deadline: ticket.marking_deadline
            ? ticket.marking_deadline
            : "",
          marking_status:
            ticket.marking_status && ticket.marking_status === 2
              ? "Chấm muộn"
              : "",
        };
      });
    },
  },
};
</script>

<style scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.table td {
  vertical-align: middle;
}
</style>
