<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <v-row>
      <!--      filter status-->
      <v-col cols="6">
        <v-row>
          <v-col cols="6" class="d-flex align-items-center pb-0">
            <label class="text-body-1 font-weight-medium mb-6"
              >Thống kê theo:</label
            >
            <v-radio-group
              class="mt-0 pt-0 ml-6"
              v-model="statistical_status"
              row
            >
              <v-radio label="marker" :value="display_view.marker"></v-radio>
              <v-radio label="class" :value="display_view.class"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" class="d-flex align-items-center pb-0">
            <label class="text-body-1 font-weight-medium mb-6">Course:</label>
            <v-autocomplete
              class="pl-4"
              :items="course_tags"
              item-text="name.vi"
              item-value="id"
              v-model="course_search_input"
              solo
              color="blue-grey lighten-2"
              dense
            >
            </v-autocomplete>
          </v-col>
          <!--          filter date-->
          <v-col cols="12" class="d-flex align-items-center pb-0 pt-0">
            <div>
              <label class="text-body-1 font-weight-medium"
                >Khoảng thời gian :</label
              >
            </div>
            <div class="d-flex ml-2">
              <label class="text-body-1 font-weight-medium mb-6 pt-4"
                >Từ:</label
              >
              <date-picker
                style=""
                class="ml-2 mt-3"
                v-model="date_start_input"
                value-type="format"
                format="YYYY-MM-DD"
              />
            </div>
            <v-icon class="ml-2 mr-2 mb-3">mdi-arrow-right</v-icon>
            <div class="d-flex">
              <label class="text-body-1 font-weight-medium mb-6 pt-4"
                >Đến:</label
              >
              <date-picker
                style=""
                class="ml-2 mt-3"
                v-model="date_end_input"
                value-type="format"
                format="YYYY-MM-DD"
              />
            </div>
          </v-col>
          <v-col cols="12" class="d-flex align-items-center pb-0">
            <label class="text-body-1 font-weight-medium mb-6"
              >Lọc theo ngày:</label
            >
            <v-radio-group
              class="mt-0 pt-0"
              v-model="is_filter_by_marking_date"
              row
            >
              <v-radio label="Giáo viên đã chấm" :value="1"></v-radio>
              <v-radio label="Học sinh nộp" :value="0"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <b-form-input
          style="float: right; width: 350px"
          placeholder="Search (class or marker)"
          v-model="search_input"
        ></b-form-input>
      </v-col>
      <!--      btn filter-->
      <v-col cols="12" class="text-center">
        <v-btn
          color="cyan"
          class="ma-2 font-weight-bold"
          outlined
          large
          @click="btnFilterData"
        >
          <v-icon left>mdi-magnify</v-icon> Tìm kiếm
        </v-btn>
      </v-col>

      <v-col cols="12">
        <DisplayMarker
          :markers="marker_tags"
          :statistical_status="statistical_status"
          :data_statistical="filterDataNotEmptyTicket(statistical_markers)"
          :filter_input="search_input"
          v-if="statistical_status === display_view.marker"
        />
        <DisplayMarker
          :markers="marker_tags"
          :statistical_status="statistical_status"
          :data_statistical="statistical_class"
          :filter_input="search_input"
          v-if="statistical_status === display_view.class"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import DisplayMarker from "@/view/pages/marking-management/DisplayStatistical/Marker";

const DISPLAY_BY_MARKER = 1;
const DISPLAY_BY_CLASS = 2;
export default {
  name: "StatisticalMarkingTicket",
  components: {
    DisplayMarker,
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      is_call_api: false,
      statistical_status: 1,
      date_start_input: null,
      date_end_input: null,
      statistical_markers: [],
      statistical_class: [],
      search_input: null,
      is_filter_by_marking_date: 1,
      course_tags: [],
      course_search_input: null,
      marker_tags: [],
      display_view: {
        marker: DISPLAY_BY_MARKER,
        class: DISPLAY_BY_CLASS,
      },
    };
  },
  watch: {
    // statistical_status(val) {
    //   if(val === 1) {
    //     this.getStatisticalMarker();
    //   } else {
    //     this.getStatisticalClass();
    //   }
    // }
  },
  async created() {
    if (this.canAccess("statistical-ticket")) {
      await this.getAllCourse();
      await this.getListMarker();
      await this.checkGetData();
    }
  },
  mounted() {},
  methods: {
    canAccess(permission) {
      return this.$utils.canAccess(permission);
    },
    checkGetData() {
      if (this.statistical_status === DISPLAY_BY_MARKER) {
        this.getStatisticalMarker();
      } else {
        this.getStatisticalClass();
      }
    },
    async getAllCourse() {
      let seft = this;
      await ApiService.get("prep-app/courses/limit").then(function (response) {
        if (response.status === 200) {
          seft.course_tags = [
            { id: null, name: { en: "", vi: "" } },
            ...response.data,
          ];
        }
      });
    },
    getListMarker() {
      let vm = this;
      ApiService.get("prep-app/markers").then(function (response) {
        if (response.status === 200) {
          vm.marker_tags = response.data;
        }
      });
    },
    async getStatisticalMarker() {
      let vm = this;
      let url = "prep-app/marking-ticket/statistical-marker?";
      let checkUrl = this.checkUrlStatisticalTicket(url);
      this.is_call_api = true;
      await ApiService.get(checkUrl).then(function (res) {
        if (res.status === 200) {
          vm.statistical_markers = res.data;
          vm.is_call_api = false;
        }
      });
    },
    async getStatisticalClass() {
      let vm = this;
      let url = "prep-app/marking-ticket/statistical-class?";
      let checkUrl = this.checkUrlStatisticalTicket(url);
      this.is_call_api = true;
      await ApiService.get(checkUrl).then(function (res) {
        if (res.status === 200) {
          vm.statistical_class = res.data;
          vm.is_call_api = false;
        }
      });
    },
    checkUrlStatisticalTicket(url) {
      if (this.date_start_input != null) {
        url = url + "date_start=" + this.date_start_input;
      }
      if (this.date_end_input != null) {
        url = url + "&date_end=" + this.date_end_input;
      }
      if (
        !(this.course_search_input == null || this.course_search_input === "")
      ) {
        url = url + "&course_id=" + this.course_search_input;
      }
      url = url + "&is_filter_marking_date=" + this.is_filter_by_marking_date;
      return url;
    },
    btnFilterData() {
      if (
        (this.date_start_input != null && this.date_end_input == null) ||
        (this.date_start_input == null && this.date_end_input != null)
      ) {
        this.$toasted.error("Hãy nhập đủ khoảng thời gian !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        return;
      }
      this.checkGetData();
    },
    filterDataNotEmptyTicket(data_statistical) {
      return data_statistical.filter((e) => {
        return e.count_ticket !== 0;
      });
    },
  },
};
</script>

<style scoped></style>
